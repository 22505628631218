import "./App.css";
import {Footer} from "./components/footer";
import {DonationSegment} from "./components/donationSegment";
import ResponsiveAppBar from "./header";
import {Outlet} from "react-router-dom";

export function App() {
    return (
        <div className="App">
            <ResponsiveAppBar/>
            <Outlet/>
            <DonationSegment/>
            <Footer/>
        </div>
    );
}

export default App;
