import styled, {keyframes} from "styled-components";
import {ContentLoad} from "../components/animations";
import {Content} from "../components/elements";
import showdown from "../assets/MinigameShowdownCropped.png";
import catAndCapy from "../assets/catandcapy.png";

const IntroSpan = styled.span`
  font-size: 1.2em;

  // margin-left: 1rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Filler = styled.div`
  min-height: 100vh;
`;

const ContentHome = styled(Content)`
  // animation: ${ContentLoad} 700ms ease-out;
`;
const GameContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  grid-gap: 2rem;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 1rem;
    border-radius: 0.5rem;
    place-items: normal start;
  }
`;
const GameTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  justify-content: center;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  width: 100%;
  max-width: 725px;
  margin-top: 6rem;

  @media (max-width: 768px) {
    max-width: 450px;
    margin-top: 2rem;
    ${GameContainer}:nth-child(2) ${GameTextContainer} {
      order: 1;
    }
  }
  @media (max-width: 480px) {
    max-width: 350px;
  }
`;

const GameImg = styled.img`
  width: 100%;
  height: 12rem;
  object-fit: contain;
  @media (max-width: 768px) {
    object-fit: cover;
  }
`;

const GameText = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  text-align: start;
`;

const GamePageButton = styled.a`
  font-size: 1.2rem;
  font-weight: 600;
  //width: 200px;
  text-align: end;
  border: 1px solid white;
  border-radius: 8px;
  display: flex;
  margin-right: auto;
  padding: 10px 20px;
  background-color: black;
  color: white;
`;

const MobileButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 70px;

`;

const catAndCapyText = `Cat and Capy is a 3D platformer where you play as a cat and a capybara. The game is currently in Alpha and will be updated continously.`;

const showdownText = `Minigame Showdown is a multiplayer game where you compete in different minigames with your friends.`;

export default function Home() {
    return (
        <ContentHome>
            <Content>
                <h1>Matato games</h1>
                <IntroSpan>
                    I am a game developer based in Norway. I am currently working on two games. One is a 3D platformer
                    called <b>Cat and Capy</b> and the other is a multiplayer game called <b>Minigame Showdown</b>.
                </IntroSpan>
                <Container>
                    <GameContainer>
                        <GameImg
                            src={catAndCapy}
                            alt="catAndCapy"
                            style={{
                                objectFit: "cover",
                            }}
                        />
                        <GameTextContainer>
                            <GameText>Cat and Capy</GameText>
                            <p>{catAndCapyText}</p>
                            <GamePageButton href="https://matatogames.itch.io/cat-and-capy">Download Page</GamePageButton>
                            {/*<div style={{ overflow: "hidden" }}>*/}
                            {/*  <div style={{ position: "relative", left: "-38%" }}>*/}
                            {/*    <iframe frameBorder="0"*/}
                            {/*            src="https://itch.io/embed/2055139?linkback=true" width="552" height="167"><a*/}
                            {/*      href="https://matatogames.itch.io/cat-and-capy">Cat and Capy - Alpha by Matato</a></iframe>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            <div>

                            </div>
                        </GameTextContainer>
                    </GameContainer>

                    <GameContainer>
                        <GameTextContainer>
                            <GameText>Minigame Showdown</GameText>
                            <p>{showdownText}</p>
                            <MobileButtonsContainer>
                                <a
                                    href="https://apps.apple.com/us/app/minigame-showdown/id6450846407?itsct=apps_box_badge&amp;itscg=30200"
                                    style={{
                                        gridColumn: 1,
                                    }}>
                                    <img
                                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1689724800"
                                        alt="Download on the App Store"
                                        style={{
                                            height: "100%",
                                            padding: "11px",
                                        }}
                                    />
                                </a>
                                <div
                                    style={{
                                        // display: "inline-block",
                                        overflow: "hidden",
                                        borderRadius: "13px",
                                        // width: "250px",
                                        height: "100%",
                                        gridColumn: 2,
                                    }}>
                                    <a
                                        style={{
                                            display: 'inherit' ,
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        href='https://play.google.com/store/apps/details?id=com.Matato.MinigameShowdown&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                        <img
                                            style={{display: 'inherit', height: '100%'}}
                                            alt='Get it on Google Play'
                                            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                                    </a>

                                </div>
                                {/*<a*/}
                                {/*    // href="https://apps.apple.com/us/app/minigame-showdown/id6450846407?itsct=apps_box_badge&amp;itscg=30200"*/}
                                {/*    style={{*/}
                                {/*        display: "inline-block",*/}
                                {/*        overflow: "hidden",*/}
                                {/*        borderRadius: "13px",*/}
                                {/*        width: "250px",*/}
                                {/*        margin: "0 0 -30px -60px",*/}
                                {/*        height: "83px",*/}
                                {/*        scale: "0.7",*/}
                                {/*        backgroundColor: "white",*/}
                                {/*        border: "2px white",*/}
                                {/*        gridColumn: 2,*/}
                                {/*    }}>*/}
                                {/*    <div style={{*/}
                                {/*        color: "black",*/}
                                {/*        display: "flex",*/}
                                {/*        justifyContent: "center",*/}
                                {/*        alignItems: "center",*/}
                                {/*        height: "100%",*/}
                                {/*        fontWeight: 600,*/}
                                {/*    }}>Coming to the Play Store soon!*/}
                                {/*    </div>*/}
                                {/*    /!*<img*!/*/}
                                {/*    /!*  src=""*!/*/}
                                {/*    /!*  alt="Download on the Play Store" style={{ borderRadius: "13px", width: "250px", height: "83px" }}/>*!/*/}
                                {/*</a>*/}
                            </MobileButtonsContainer>
                        </GameTextContainer>
                        <GameImg
                            src={showdown}
                            alt="showdown"
                            style={{
                                objectFit: "contain",
                            }}
                        />
                    </GameContainer>
                </Container>
            </Content>
        </ContentHome>
    );
}
