import { keyframes } from "styled-components";

export const ContentLoad = keyframes`
   0%{
    transform: translateX(-100%);
   }
   100% {
    transform: translateX(0);
   }
`;

export const NavBarLoad = keyframes`
    0% { 
        transform: translateY(-100%)
    }
    100% {
        transform: translateY(0);
    }
`;
