import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from "./routes/contact";
import Games from "./routes/games";
import Music from "./routes/music";
import Home from "./routes/home";
import { Merch } from "./routes/merch";
import Videos from "./routes/videos";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path="Games" element={<Home />} />
          <Route path="Videos" element={<Videos />} />
          {/*<Route path="Music" element={<Music />} />*/}
          <Route path="Merch" element={<Merch />} />
          <Route path="Contact" element={<Contact />} />
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
