import styled from "styled-components";
import instagram from "../assets/instagram.svg";
import tiktok from "../assets/tiktok.svg";
import youtube from "../assets/youtube.svg";

const FooterComponent = styled.footer`
  grid-area: footer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000040;

  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1rem;
  min-height: 10vh;
  margin-top: 3rem;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const FooterText = styled.p`
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const FooterIcons = styled.img`
  width: 1.75rem;
  height: 1.75rem;
  :hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;

export const Footer = () => {
  return (
    <FooterComponent>
      <FooterContainer>
        <FooterText>Contact: matatogamestudio@gmail.com</FooterText>
        <IconContainer>
          <FooterIcons src={instagram} />
          <FooterIcons src={tiktok} />
          <FooterIcons src={youtube} />
        </IconContainer>
        <FooterText>Copyright © 2023 | MatatoGames | All Rights Reserved </FooterText>
      </FooterContainer>
    </FooterComponent>
  );
};
